import React from 'react'
import { resolvePath } from '../utils/helpers.js'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import Card from '../components/card'

const RootTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <div id="sub-page">
      <Layout>
      <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}           
        />

        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}             
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
              <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{__html: post.frontmatter.openingpara}}
                />
                <div
                  className="mt-3 richtext divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.section && (
          <section className="bg-light-blue">
            <MDBContainer>
              <MDBRow>
                <>
                  {post.frontmatter.section.map(sections => {
                    return (
                      <Card
                        collg="4"
                        colmd="6"
                        height="13.5rem"
                        title={sections.title}
                        subtitle={sections.subtitle}
                        description={sections.description}
                        image={sections.image.childImageSharp.gatsbyImageData}
                        alt={sections.alttext}
                        placement={sections.placement}
                        link={resolvePath(sections.link)}
                      />
                    )
                  })}
                </>
              </MDBRow>
            </MDBContainer>
          </section>
        )}
      
      </Layout>
    </div>
  )
}
export default RootTemplate

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }    
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "root" } }
    ) {
      frontmatter {
        meta {
          title
          description 
        }        
        title
        subtitle
        openingpara
        alttext
        ctatext
        ctaslug         
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        section {
          title
          subtitle
          description
          image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
          placement
          alttext
          link
        }
      }
      html
    }
  }
`
